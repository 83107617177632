import React, { useState, useEffect } from "react";
import StockOutBtn from "./StockOutBtn";
import StockInBtn from "./StockInBtn";
import GlobalSearch from "./GlobalSearch";
import GlobalScan from "./GlobalScan";
import { useNavigate, useLocation } from "react-router-dom";
// import logo from '../../../images/logo/logo.png';

const Header = () => {
	const [headerFix, setheaderFix] = useState(false);
	const navigate = useNavigate()
	const location = useLocation()

	useEffect(() => {
		window.addEventListener("scroll", () => {
			setheaderFix(window.scrollY > 50);
		});
	}, []);

	const handlePrintBarcode = () => {
		const currentUrl = location.pathname
		navigate(`/barcode?action=create-barcode&url=${currentUrl}`)
	}

	return (
		<div className={`header ${headerFix ? "is-fixed" : ""}`}>
			<div className="header-content">
				<nav className="navbar navbar-expand">
					<div className="collapse navbar-collapse justify-content-between">
						<h4 className="department-name">{process.env.REACT_APP_DEPARTMENT_NAME || ""}</h4>
						<div className="header-left">
							<GlobalSearch />
						</div>

						<ul className="navbar-nav header-right">
							<li className="nav-item align-items-center">
								<GlobalScan />
								<StockInBtn />
								<StockOutBtn />
								<button className="btn btn-sm btn-primary" onClick={handlePrintBarcode}>
									<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="25" height="25" viewBox="0 0 64 64" >
										<g transform="scale(0.9)">
											<path d="M58 25h-5V6a.996.996 0 0 0-.293-.707l-4-4a.999.999 0 0 0-1.414 0L44 4.586l-3.293-3.293a.999.999 0 0 0-1.414 0L36 4.586l-3.293-3.293a.999.999 0 0 0-1.414 0L28 4.586l-3.293-3.293a.999.999 0 0 0-1.414 0L20 4.586l-3.293-3.293a.999.999 0 0 0-1.414 0l-4 4A.996.996 0 0 0 11 6v19H6c-2.757 0-5 2.243-5 5v22c0 1.627.793 3.061 2 3.974V60c0 1.654 1.346 3 3 3h52c1.654 0 3-1.346 3-3v-4.026c1.207-.914 2-2.348 2-3.974V30c0-2.757-2.243-5-5-5zm-1 2v6h-4v-6zM16 3.414l3.293 3.293a.999.999 0 0 0 1.414 0L24 3.414l3.293 3.293a.999.999 0 0 0 1.414 0L32 3.414l3.293 3.293a.999.999 0 0 0 1.414 0L40 3.414l3.293 3.293a.999.999 0 0 0 1.414 0L48 3.414l3 3V33H13V6.414zM11 33H7v-6h4zm48 27c0 .552-.449 1-1 1H6c-.551 0-1-.448-1-1v-3.101c.323.066.658.101 1 .101h52c.342 0 .677-.035 1-.101zm2-8c0 1.654-1.346 3-3 3H6c-1.654 0-3-1.346-3-3V30c0-1.302.839-2.402 2-2.816V34a1 1 0 0 0 1 1h52a1 1 0 0 0 1-1v-6.816c1.161.414 2 1.514 2 2.816z" fill="currentColor"></path>
											<path d="M55 45c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4zm0 6c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zM45 45c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4zm0 6c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zM9 45c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4zm0 6c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zM17 27h30a1 1 0 0 0 1-1V10a1 1 0 0 0-1-1H17a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1zm1-16h28v14H18z" fill="currentColor"></path>
											<path d="M20 13h2v10h-2zM38 13h2v10h-2zM41 13h2v10h-2zM23 13h1v10h-1zM25 13h1v10h-1zM27 13h1v10h-1zM29 13h3v10h-3zM33 13h2v10h-2zM36 13h1v10h-1zM16 29h2v2h-2zM20 29h2v2h-2zM24 29h24v2H24z" fill="currentColor"></path>
										</g>
									</svg>
								</button>
							</li>
							<li className="nav-item ps-3">
								{/* <div className="company-logo">
									<img src={logo} alt="logo" />
									<span className="brand-title">InteliStock</span>
								</div> */}
							</li>
						</ul>
					</div>
				</nav>
			</div>
			<div className="header-bar"></div>
		</div>
	);
};

export default Header;
