import React from "react";
import { sendDailyReportMailApi } from "../../../services/OtherService";
import { toast } from "react-toastify";

const SendDailyReportMail = () => {
  const sendMail = () => {
    // Ask for confirmation before sending the email
    const isConfirmed = window.confirm(
      "Are you sure you want to send the Daily Stock Report email?"
    );
    
    if (isConfirmed) {
      sendDailyReportMailApi()
        .then((resp) => {
          toast.success(resp.data.message);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    } else {
      toast.info("The email sending was canceled.");
    }
  };

  return (
    <div className="row mt-2">
      <div className="col-ms-12">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between text-wrap align-items-center">
              <h4 className="heading mb-0">
                Automatically send the Daily Stock Report email every day at 6 PM. You can manually trigger it here.
              </h4>
              <button
                className="btn btn-primary btn-sm ms-1"
                data-bs-toggle="offcanvas"
                onClick={sendMail}
              >
                <i className="fa-solid fa-table-list"></i> Send Daily Stock Report Mail <i className="ms-2 fa-solid fa-paper-plane"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendDailyReportMail;
