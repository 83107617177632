import axiosInstance from './AxiosInstance';
const apiUrl = "api/products"

export function getProductList(page, page_size, query, stock_type) {
    return axiosInstance.get(`${apiUrl}?page=${page}&page_size=${page_size}&query=${query}&stock_type=${stock_type}`);
}

export function getProduct(rackId) {
    return axiosInstance.get(`${apiUrl}/${rackId}`);
}

export function getProductStocks(prodId, rack_id) {
    return axiosInstance.get(`${apiUrl}/${prodId}?rack_id=${rack_id}`);
}

export function createProduct(ProductData) {
    return axiosInstance.post(apiUrl, ProductData);
}

export function stockInProduct(stockData) {
    return axiosInstance.post('api/stocks', stockData);
}

export function stockOutProduct(stockData) {
    return axiosInstance.put('api/stocks', stockData);
}

export function updateProduct(ProductData) {
    return axiosInstance.put(apiUrl, ProductData);
}

export function deleteProduct(rackId) {
    return axiosInstance.delete(`${apiUrl}/${rackId}`);
}

export function getStockHistoryList(page, page_size, query, status) {
    return axiosInstance.get(`api/stock_history?page=${page}&page_size=${page_size}&query=${query}&status=${status}`);
}

export function getProductStockHistoryList(id, page, page_size, query) {
    return axiosInstance.get(`api/stock_history/${id}?page=${page}&page_size=${page_size}&query=${query}`);
}

export function getBarcodeList(page, page_size, query, status_filter) {
    return axiosInstance.get(`api/barcode?page=${page}&page_size=${page_size}&query=${query}&status=${status_filter}`);
}
export function validateProductBarcode(product_barcode_no) {
    return axiosInstance.get(`api/barcode?product_barcode_no=${product_barcode_no}`);
}

export function createBarcode(data) {
    return axiosInstance.post('api/barcode', data);
}

export function getStockByBarcode(barcode) {
    return axiosInstance.get(`api/stocks?query=${barcode}`);
}

export function getBarcodeAction(barcode) {
    return axiosInstance.get(`api/barcode_action/${barcode}`);
}

export function dashboard() {
    return axiosInstance.get('api/dashboard');
}
export function dashboardStockOverview(view_type = "weekly") {
    return axiosInstance.get(`api/dashboard/stock_overview?view_type=${view_type}`);
}
export function dashboardProductStockOverview(id, view_type = "weekly") {
    return axiosInstance.get(`api/dashboard/stock_overview/${id}?view_type=${view_type}`);
}

export function dashboardProductUses(view_type = "weekly", stock_type = "stockin") {
    return axiosInstance.get(`api/dashboard/product_uses?view_type=${view_type}&stock_type=${stock_type}`);
}

export function dashboardProductOverUnder(stock_type = "stockunder") {
    return axiosInstance.get(`api/dashboard/product_under_over?stock_type=${stock_type}`);
}

export function searchProduct(query) {
    return axiosInstance.get(`api/search/product?query=${query}`);
}

export function updateProfile(data) {
    return axiosInstance.post(`api/auth/profile`, data);
}


export function getProductForecastList(id = null) {
    if (id) {
        return axiosInstance.get(`api/product_forecast/${id}`);
    }
    return axiosInstance.get('api/product_forecast');
}

export function getTopProductForecastList() {
    return axiosInstance.get('api/top_product_forecast');
}

// Analysis

export function ABCAnalysisApi(view_type = "all") {
    return axiosInstance.get(`api/abc_analysis?view_type=${view_type}`);
}

export function VEDAnalysisApi(view_type = "all") {
    return axiosInstance.get(`api/ved_analysis?view_type=${view_type}`);
}

export function HMLAnalysisApi(view_type = "all") {
    return axiosInstance.get(`api/hml_analysis?view_type=${view_type}`);
}

export function FSNAnalysisApi(view_type = "all") {
    return axiosInstance.get(`api/fsn_analysis?view_type=${view_type}`);
}

export function StockRatioAnalysisApi(view_type = "1M") {
    return axiosInstance.get(`api/stock_age_analysis?view_type=${view_type}`);
}

export function SDEAnalysisApi() {
    return axiosInstance.get('api/sde_analysis');
}

export function importProduct(ProductData) {
    return axiosInstance.patch(apiUrl, ProductData);
}

// report 
export function getMaterialReportList(page, page_size, query) {
    return axiosInstance.get(`api/report/material?page=${page}&page_size=${page_size}&query=${query}`);
}