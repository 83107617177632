import React, { useState, useEffect } from 'react';
import Pagination from '../../constant/Pagination';
import { toast } from 'react-toastify';
import { Offcanvas, Form, Modal } from 'react-bootstrap';
import { CancelRequest } from '../../../services/AxiosInstance';
import { getDesignationList, getUserList, createUser, updateUser, deleteUser } from '../../../services/OtherService';
import TableLoaderEffect from '../../constant/TableLoader';

const UserPage = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [isModalOpen, setIsModalOpen] = useState({ open: false, type: 'Add' });
    const [empForm, setEmpForm] = useState({
        name: '', email: '', mobile_number: '', personnel_number: '',
        password: '', designation: '', is_allow_email_daily_report: false, is_allow_email_low_stock_alert: false
    });
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [designationList, setDesignationList] = useState([])
    const recordsPage = 10;


    useEffect(() => {
        setIsLoading(true)
        getUserList(currentPage, recordsPage, searchQuery).then((resp) => {
            setData(resp.data.data)
            setTotal(resp.data.total)
            setIsLoading(false)
        }).catch((error) => {
            console.log(error.response.data.message)
            setIsLoading(false)
        })

    }, [])

    const fetchDesignation = () => {
        getDesignationList().then((resp) => {
            setDesignationList(resp.data.data)
        }).catch((error) => {
            console.log(error.response.data.message)
        })
    }

    const toggleModal = (type = "Add", emp = null) => {
        let open = isModalOpen.open
        if (type === "Add") {
            open = !open
        } else if (type === "Edit" && emp) {
            setEmpForm({
                emp_id: emp.id, name: emp.name,
                email: emp.email, mobile_number: emp.mobile_number,
                personnel_number: emp.personnel_number, password: '',
                designation: emp.designation.id, status: emp.status,
                is_allow_email_daily_report: emp.is_allow_email_daily_report,
                is_allow_email_low_stock_alert: emp.is_allow_email_low_stock_alert
            });
            open = true
        }
        else if (type === "Delete" && emp) {
            setEmpForm(emp);
            open = true
        }
        if (isModalOpen.open && emp === null) {
            setEmpForm({ name: '', email: '', mobile_number: '', personnel_number: '', password: '', designation: '', is_allow_email_daily_report: false, is_allow_email_low_stock_alert: false });
            setDesignationList([])
        } else if (!designationList.length) {
            fetchDesignation()
        }
        setIsModalOpen({ type, open })
    }

    const handleChangeAdd = (e) => {
        const { name, value } = e.target
        setEmpForm({ ...empForm, [name]: value })
    }

    const handlePagination = (page) => {
        setIsLoading(true)
        const new_page = page.selected + 1
        getUserList(new_page, recordsPage, '').then((resp) => {
            setData(resp.data.data)
            setTotal(resp.data.total)
            setCurrentPage(new_page)
            setIsLoading(false)
        }).catch((error) => {
            alert(error.response.data.message)
            setIsLoading(false)
        })
    }

    const handleSearchChange = (e) => {
        const { value } = e.target
        if (value.length > 0) {
            CancelRequest()
        }
        getUserList(currentPage, recordsPage, value).then((resp) => {
            if (resp) {
                setData(resp.data.data)
                setTotal(resp.data.total)
            }
        }).catch((error) => {
            console.log(error.response.data.message)
        })
        setSearchQuery(value)
    }



    const handleSubmit = async (e) => {
        e.preventDefault()
        if (isModalOpen.type === "Add") {
            try {
                const resp = await createUser(empForm)
                const resp_data = resp.data.data
                setData([resp_data, ...data])
                setTotal(total + 1)
                toast.success(resp.data.message)
                toggleModal()
            } catch (error) {
                toast.error(error.response.data.message)
            };
        } else {
            try {
                const response = await updateUser(empForm)
                const resp_data = response.data
                setData(data.map(user => user.id === resp_data.data.id ? { ...resp_data.data } : user))
                toast.success(resp_data.message)
                toggleModal()
            } catch (error) {
                console.log(error)
                if (error && error.response) {
                    toast.error(error.response.data.message)
                }
            };
        }
    }

    const handleDelete = async () => {
        try {
            const response = await deleteUser(empForm.emp_id)
            const resp_data = response.data
            setData(data.filter(user => user.id !== empForm.emp_id))
            toast.success(resp_data.message)
            toggleModal()
        } catch (error) {
            console.log(error)
            if (error && error.response) {
                toast.error(error.response.data.message)
            }
        };

    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                                    <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                                        <h4 className="heading mb-0">Users</h4>
                                        <div className='d-flex'>
                                            <div className='form-group me-5'>
                                                <input type="search" name="serach" className="form-control"
                                                    placeholder="Search"
                                                    value={searchQuery} onChange={handleSearchChange} />
                                            </div>
                                            <button className="btn btn-primary ms-1" data-bs-toggle="offcanvas"
                                                onClick={() => toggleModal()}
                                            >Add User</button>
                                        </div>
                                    </div>
                                    <div id="employee-tbl_wrapper" className="dataTables_wrapper no-footer">
                                        <table id="empoloyees-tblwrapper" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Personnel Number</th>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Mobile</th>
                                                    <th>Designation</th>
                                                    <th>Status</th>
                                                    <th>Mail Alert</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {isLoading ? <TableLoaderEffect colLength={6} /> : data.map((item, index) => (
                                                    <tr key={index}>
                                                        <td><span className='text-primary cursor-pointer fw-bolder' onClick={() => toggleModal("Edit", item)}>{item.personnel_number}</span></td>
                                                        <td><span>{item.name}</span></td>
                                                        <td>{item.email}</td>
                                                        <td><span>{item.mobile_number}</span></td>
                                                        <td><span>{item.designation.name}</span></td>
                                                        <td>
                                                            {item.status === "Active" ? (
                                                                <span className={`badge light border-0 badge-success`}>Active</span>
                                                            ) : (
                                                                <span className={`badge light border-0 badge-danger`}>Inactive</span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            {item.is_allow_email_low_stock_alert && (
                                                                <span className={`badge light border-0 badge-primary`}>
                                                                    <i className="fa-solid fa-arrow-trend-down"></i>
                                                                </span>
                                                            ) }
                                                            {item.is_allow_email_daily_report && (
                                                                <span className={`badge light border-0 ms-2 badge-primary`}><i className="fa-solid fa-table-list"></i></span>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                        {isLoading ? <div style={{ height: '4rem' }}></div> : <Pagination
                                            currentPage={currentPage}
                                            recordsPage={recordsPage}
                                            dataLength={total}
                                            handlePagination={handlePagination}
                                        />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* add employee */}
            <Offcanvas show={isModalOpen.open && isModalOpen.type === "Add"}
                onHide={() => toggleModal()}
                onExited={() => console.log("exied")}
                className="offcanvas-end" placement='end'>
                <div className="offcanvas-header">
                    <h5 className="modal-title" id="#gridSystemModal">Add User</h5>
                    <button type="button" className="btn-close"
                        onClick={() => toggleModal()}>
                    </button>
                </div>
                <div className="offcanvas-body">
                    <div className="container">
                        <Form onSubmit={(e) => handleSubmit(e)}>
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="mb-3">
                                        <label htmlFor="employee_id" className="form-label">Personnel Number <span className="text-danger">*</span></label>
                                        <input type="text"
                                            className="form-control"
                                            id="personnel_number"
                                            placeholder="Enter Personnel Number"
                                            value={empForm.personnel_number}
                                            name='personnel_number'
                                            required
                                            onChange={handleChangeAdd} />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="name" className="form-label">User Name <span className="text-danger">*</span></label>
                                        <input type="text" autoFocus className="form-control" id="name" placeholder="Enter Name"
                                            value={empForm.name} name='name' onChange={handleChangeAdd} required />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">Email <span className="text-danger">*</span></label>
                                        <input type="email" className="form-control" id="email" placeholder="Enter Email"
                                            value={empForm.email} name='email' onChange={handleChangeAdd} required />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="mobile_number" className="form-label">Mobile Number <span className="text-danger">*</span></label>
                                        <input type="number"
                                            className="form-control"
                                            id="mobile_number"
                                            placeholder="Enter Mobile Number"
                                            value={empForm.mobile_number}
                                            name='mobile_number'
                                            required
                                            onChange={handleChangeAdd} />
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label">Designation <span className="text-danger">*</span></label>
                                        <select className="default-select form-control" name='designation' onChange={handleChangeAdd} required>
                                            <option value=''>Select Designation</option>
                                            {designationList.map((item, index) => (
                                                <option value={item.value} key={index}>{item.label}</option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="password" className="form-label">Password <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control" id="password" placeholder="Enter Password"
                                            value={empForm.password} name='password' onChange={handleChangeAdd} required />
                                    </div>

                                    <div className='mb-3'>
                                        <h5>Email Alert</h5>
                                        <div className="form-check custom-checkbox mb-2 form-check-inline">
                                            <input type="checkbox" className="form-check-input"
                                                id="is_allow_email_daily_report"
                                                name="is_allow_email_daily_report"
                                                checked={empForm.is_allow_email_daily_report}
                                                onChange={(e) => setEmpForm({ ...empForm, is_allow_email_daily_report: e.target.checked })} />
                                            <label className="form-check-label" htmlFor="is_allow_email_daily_report"><i className="fa-solid fa-table-list"></i> Daily Report</label>
                                        </div>

                                        <div className="form-check custom-checkbox mb-2 form-check-inline">
                                            <input type="checkbox" className="form-check-input"
                                                id="is_allow_email_low_stock_alert"
                                                name="is_allow_email_low_stock_alert"
                                                checked={empForm.is_allow_email_low_stock_alert}
                                                onChange={(e) => setEmpForm({ ...empForm, is_allow_email_low_stock_alert: e.target.checked })} />
                                            <label className="form-check-label" htmlFor="is_allow_email_low_stock_alert"><i className="fa-solid fa-arrow-trend-down"></i> Low Stock Alert</label>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div>
                                <button type="submit" className="btn btn-primary me-1">Create</button>
                                <button type="button" onClick={() => toggleModal()} className="btn btn-danger light ms-1">Cancel</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </Offcanvas>

            {/* add user end */}

            {/* edit user */}
            <Offcanvas show={isModalOpen.open && isModalOpen.type === "Edit"}
                onHide={() => toggleModal()}
                className="offcanvas-end" placement='end'>
                <div className="offcanvas-header">
                    <h5 className="modal-title" id="#gridSystemModal">Edit User</h5>
                    <button type="button" className="btn-close"
                        onClick={() => toggleModal()}>
                    </button>
                </div>
                <div className="offcanvas-body">
                    <div className="container">
                        <Form onSubmit={(e) => handleSubmit(e)}>
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="mb-3">
                                        <label htmlFor="employee_id" className="form-label">Personnel Number <span className="text-danger">*</span></label>
                                        <input type="text"
                                            className="form-control"
                                            id="personnel_number"
                                            placeholder="Enter Personnel Number"
                                            value={empForm.personnel_number}
                                            name='personnel_number'
                                            required
                                            onChange={handleChangeAdd} />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="name" className="form-label">User Name <span className="text-danger">*</span></label>
                                        <input type="text" autoFocus className="form-control" id="name" placeholder="Enter Name"
                                            value={empForm.name} name='name' onChange={handleChangeAdd} required />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">Email <span className="text-danger">*</span></label>
                                        <input type="email" className="form-control" id="email" placeholder="Enter Email"
                                            value={empForm.email} name='email' onChange={handleChangeAdd} required />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="mobile_number" className="form-label">Mobile Number <span className="text-danger">*</span></label>
                                        <input type="number"
                                            className="form-control"
                                            id="mobile_number"
                                            placeholder="Enter Mobile Number"
                                            value={empForm.mobile_number}
                                            name='mobile_number'
                                            required
                                            onChange={handleChangeAdd} />
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label">Designation <span className="text-danger">*</span></label>
                                        <select className="default-select form-control" name='designation' value={empForm.designation} onChange={handleChangeAdd} required>
                                            <option value=''>Select Designation</option>
                                            {designationList.map((item, index) => (
                                                <option value={item.value} key={index} >{item.label}</option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="password" className="form-label">New Password <span>(Optional)</span></label>
                                        <input type="text" className="form-control" id="password" placeholder="Enter New Password"
                                            value={empForm.password} name='password' onChange={handleChangeAdd} />
                                    </div>

                                    <div className='mb-3'>
                                        <h5>Email Alert</h5>
                                        <div className="form-check custom-checkbox mb-2 form-check-inline">
                                            <input type="checkbox" className="form-check-input"
                                                id="is_allow_email_daily_report"
                                                name="is_allow_email_daily_report"
                                                checked={empForm.is_allow_email_daily_report}
                                                onChange={(e) => setEmpForm({ ...empForm, is_allow_email_daily_report: e.target.checked })} />
                                            <label className="form-check-label" htmlFor="is_allow_email_daily_report"><i className="fa-solid fa-table-list"></i> Daily Report</label>
                                        </div>

                                        <div className="form-check custom-checkbox mb-2 form-check-inline">
                                            <input type="checkbox" className="form-check-input"
                                                id="is_allow_email_low_stock_alert"
                                                name="is_allow_email_low_stock_alert"
                                                checked={empForm.is_allow_email_low_stock_alert}
                                                onChange={(e) => setEmpForm({ ...empForm, is_allow_email_low_stock_alert: e.target.checked })} />
                                            <label className="form-check-label" htmlFor="is_allow_email_low_stock_alert"><i className="fa-solid fa-arrow-trend-down"></i>  Low Stock Alert</label>
                                        </div>

                                    </div>

                                    <div className="mb-3">
                                        <label>Status</label><br />
                                        <div className="form-check custom-checkbox mb-2 form-check-inline">
                                            <input type="radio" className="form-check-input" checked={empForm.status === "Active"}
                                                id="active" name="status" value={'Active'} onChange={handleChangeAdd} />
                                            <label className="form-check-label" htmlFor="active">Active</label>
                                        </div>
                                        <div className="form-check custom-checkbox mb-2 form-check-inline">
                                            <input type="radio" className="form-check-input" checked={empForm.status === "Inactive"}
                                                id="inactive" name="status" value={'Inactive'} onChange={handleChangeAdd} />
                                            <label className="form-check-label" htmlFor="inactive">Inactive</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex align-items-center justify-content-between'>
                                <button type="submit" className="btn btn-primary me-1">Update</button>
                                <button type="button" onClick={() => toggleModal("Delete", empForm)} className="btn btn-danger light ms-1">Delete</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </Offcanvas>

            {/* edit user end */}

            {/* delete user */}
            <Modal className="modal fade modal-sm" id="exampleModal1" show={isModalOpen.open && isModalOpen.type === "Delete"} onHide={() => toggleModal()}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel1">Delete User</h1>
                    </div>
                    <div className="modal-body">
                        <p>Are you sure you want to delete user {empForm?.name}</p>
                        <button type="button" className="btn btn-sm btn-primary mt-3 " onClick={() => toggleModal()}>Cancel</button>
                        <button type="button" className="btn btn-sm btn-danger mt-3 ms-2" onClick={handleDelete}>Delete User</button>
                    </div>
                </div>
            </Modal>

        </>
    );
};
export default UserPage;