import axiosInstance from './AxiosInstance';
const apiUrl = "api/racks"

export function getRackList(page, page_size, query) {
    return axiosInstance.get(`${apiUrl}?page=${page}&page_size=${page_size}&query=${query}`);
}

export function getRack(rackId) {
    return axiosInstance.get(`${apiUrl}/${rackId}`);
}
export function getRackProducts(rackId) {
    return axiosInstance.get(`${apiUrl}/${rackId}`);
}


export function createRack(RackData) {
    return axiosInstance.post(apiUrl, RackData);
}

export function updateRack(RackData) {
    return axiosInstance.put(apiUrl, RackData);
}

export function deleteRack(rackId) {
    return axiosInstance.delete(`${apiUrl}/${rackId}`);
}

export function searchRack() {
    return axiosInstance.get('api/search/racks');
}

export function searchSource() {
    return axiosInstance.get('api/search/source');
}

export function searchEmployee() {
    return axiosInstance.get('api/search/employee');
}

export function searchPurpose() {
    return axiosInstance.get('api/search/purpose');
}


export function formatRack(postsData) {
    let posts = [];
    for (let key in postsData) {
        posts.push({ ...postsData[key], id: key });
    }

    return posts;
}
