import React from 'react';
import DesignationPage from './Designation';
// import DepartmentPage from './Department';
import ProductCategoryPage from './ProductCategory'
import UnitPage from './Unit';
import SendDailyReportMail from './SendDailyReportMail';

const SettingsPage = () => {

  return (
    <>
      <div className="container-fluid">
        <h4>Settings</h4>
        <div className="row">
          <div className="col-xl-4">
            <DesignationPage />
          </div>
          <div className="col-xl-4">
            <ProductCategoryPage />
          </div>
          <div className="col-xl-4">
            <UnitPage />
          </div>
        </div>
        <SendDailyReportMail />
      </div>
    </>
  );
};
export default SettingsPage;