import React, { useContext, useEffect, useState } from 'react';

//Import Components
import { ThemeContext } from "../../../context/ThemeContext";
import CardWidget from './elements/CardWidget';
import ProjectOverviewChart from './elements/ProjectOverviewChart';
import { Nav, Tab } from 'react-bootstrap';
import ActiveProjects from './elements/ActiveProjects';
import ProductNetCount from './elements/ProductNetCount';
import TopPieChart from './elements/TopProductPie';
import { connect } from 'react-redux';
import { dashboard, dashboardStockOverview, dashboardProductUses, dashboardProductOverUnder } from '../../../services/ProductService';


const Home = ({ stockUpdateKey }) => {
	const [countData, setCountData] = useState(null);
	const [lineData, setLineData] = useState(null);
	const [underStockData, setUnderStockData] = useState([]);
	const [overStockData, setOverStockData] = useState([]);
	const [stockOutData, setStockOutData] = useState([]);
	const [fastMovingData, setFastMovingData] = useState([]);
	const [stockInData, setStockInData] = useState([]);
	const [stockIdleData, setStockIdleData] = useState([]);
	const { changeBackground } = useContext(ThemeContext);

	useEffect(() => {
		changeBackground({ value: "light", label: "light" });
		dashboard().then((response) => {
			setCountData(response.data.data)
		}).catch((error) => {
			console.log(error.response.data.message)
		});

		dashboardStockOverview().then((response) => {
			setLineData(response.data.data)
		}).catch((error) => {
			console.log(error.response.data.message)
		});

		dashboardProductUses("weekly", 'stockout').then((response) => {
			setStockOutData(response.data.data)
			setFastMovingData(response.data.data)
		}).catch((error) => {
			console.log(error.response.data.message)
		});

		dashboardProductUses("weekly", 'stockin').then((response) => {
			setStockInData(response.data.data)
		}).catch((error) => {
			console.log(error.response.data.message)
		});

		dashboardProductUses("weekly", 'stockidle').then((response) => {
			setStockIdleData(response.data.data)
		}).catch((error) => {
			console.log(error.response.data.message)
		});

		dashboardProductOverUnder('stockunder').then((response) => {
			setUnderStockData(response.data.data)
		}).catch((error) => {
			console.log(error.response.data.message)
		});

		dashboardProductOverUnder('stockover').then((response) => {
			setOverStockData(response.data.data)
		}).catch((error) => {
			console.log(error.response.data.message)
		});

	}, [stockUpdateKey]);

	const handleChangeLineData = (view_type) => {
		dashboardStockOverview(view_type).then((response) => {
			setLineData(response.data.data)
		}).catch((error) => {
			console.log(error.response.data.message)
		});
	}

	const handleFastMovingData = (view_type) => {
		dashboardProductUses(view_type, 'stockout').then((response) => {
			setFastMovingData(response.data.data)
		}).catch((error) => {
			console.log(error.response.data.message)
		});
	}

	const handleChangeView = (view_type, stock_type) => {
		dashboardProductUses(view_type, stock_type).then((response) => {
			const resp_data = response.data.data
			if (stock_type === 'stockin') {
				setStockInData(resp_data)
			} else if (stock_type === 'stockout') {
				setStockOutData(resp_data)
			} if (stock_type === 'stockidle') {
				setStockIdleData(resp_data)
			}
		}).catch((error) => {
			console.log(error.response.data.message)
		});
	}

	const chartHeaderData = [
		{ title: 'W', type: 'weekly' },
		{ title: 'M', type: 'monthly' },
		{ title: 'Y', type: 'yearly' },
	];


	const stockFilter = (stock_type) => {
		return (
			<Tab.Container defaultActiveKey={'W'}>
				<Nav as="ul" className="nav nav-pills mix-chart-tab">
					{chartHeaderData.map((item, index) => (
						<Nav.Item as="li" className="nav-item" key={index}>
							<Nav.Link eventKey={item.title}
								onClick={() => handleChangeView(item.type, stock_type)}>
								{item.title}
							</Nav.Link>
						</Nav.Item>
					))}
				</Nav>
			</Tab.Container>
		)
	}

	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<CardWidget data={countData} />
				</div>
				<div className="row">

					<div className="col-xl-8 col-md-12">
						<ProjectOverviewChart data={lineData} handleChange={handleChangeLineData} />
					</div>
					<div className="col-xl-4 col-lg-6 col-md-6">
						<TopPieChart chartData={fastMovingData} handleChange={handleFastMovingData} />
					</div>

					<div className="col-xl-4 col-lg-6 col-md-6 active-p">
						{stockInData.length > 0 ? (
							<ActiveProjects tableData={stockInData} title={"Most-Received Inventory"} filter={stockFilter('stockin')} />
						) : null}
					</div>
					<div className="col-xl-4 col-lg-6 col-md-6 active-p">
						{stockOutData.length > 0 ? (
							<ActiveProjects tableData={stockOutData} title={"Fast Moving Items"} filter={stockFilter('stockout')} />
						) : null}
					</div>
					<div className="col-xl-4 col-lg-6 col-md-6 active-p">
						{stockIdleData.length > 0 ? (
							<ActiveProjects tableData={stockIdleData} title={"Dead Stock Items"} filter={stockFilter('stockidle')} />
						) : null}
					</div>
					<div className="col-xl-6 col-lg-6 col-md-6 active-p">
						{stockIdleData.length > 0 ? (
							<ProductNetCount tableData={underStockData} title={"Critical Reorder Items"} />
						) : null}
					</div>
					<div className="col-xl-6 col-lg-6 col-md-6 active-p">
						{stockIdleData.length > 0 ? (
							<ProductNetCount tableData={overStockData} title={"Overstocked Items"} />
						) : null}
					</div>
				</div>
			</div >
		</>
	)
}
const mapStateToProps = (state) => {
	return {
		stockUpdateKey: state.auth.stockUpdateKey,
	};
};
export default connect(mapStateToProps)(Home);