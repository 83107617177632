
import {
    HANDLE_SELETED_MATERIAL,
    HANDLE_SELETED_REPORT_TYPE,
    LOADING_DATA_ACTION,
    HANDLE_FROM_TO_DATE,
    GET_DATA_BY_REPORT_TYPE,
    SHOW_TOAST_ERROR,
    HANDLE_SELECTED_PERIOD,
    GET_MATERIAL_OPTIONS,
    GET_EMPLOYEE_OPTIONS,
    HANDLE_SELETED_EMPLOYEE,
    GET_PURPOSE_OPTIONS,
    HANDLE_SELETED_PURPOSE

} from './ReportTypes';

import {
    getMaterialList, getMaterialTransactionList,
    getMostRecieveMoveItemList, getCriticalReorderMaterial,
    getEmployeeReportList, getMaterailUsageList,
    searchMaterial, getDeadStockItemReportList
} from '../../services/ReportService';
import { searchEmployee, searchPurpose } from '../../services/RackService';
export function getDataByReportTypeAction() {
    function dispatchCallBack(data) {
        return {
            type: GET_DATA_BY_REPORT_TYPE,
            payload: data,
        };
    }
    return (dispatch, getState) => {
        const mystate = getState().report
        const reportType = mystate.selectedReportType.value
        if (reportType === "material") {

            getMaterialList(mystate.fromDate, mystate.toDate).then((response) => {
                const data = response ? response.data.data : []
                dispatch(dispatchCallBack(data))
            }).catch((error) => {
                dispatch(showToastError(error.response.data.message))
            });
        } else if (reportType === "material_transaction") {

            getMaterialTransactionList(mystate.fromDate, mystate.toDate, mystate.selectedMaterial.map((item) => item.value)).then((response) => {
                const data = response ? response.data.data : []
                dispatch(dispatchCallBack(data))
            }).catch((error) => {
                dispatch(showToastError(error.response.data.message))
            });
        }
        else if (reportType === "most_received_material") {

            getMostRecieveMoveItemList(mystate.fromDate, mystate.toDate, "stockin").then((response) => {
                const data = response ? response.data.data : []
                dispatch(dispatchCallBack(data))
            }).catch((error) => {
                dispatch(showToastError(error.response.data.message))
            });

        } else if (reportType === "fast_moving_material") {

            getMostRecieveMoveItemList(mystate.fromDate, mystate.toDate, "stockout").then((response) => {
                const data = response ? response.data.data : []
                dispatch(dispatchCallBack(data))
            }).catch((error) => {
                dispatch(showToastError(error.response.data.message))
            });

        } else if (reportType === "critical_reorder_item") {

            getCriticalReorderMaterial().then((response) => {
                const data = response ? response.data.data : []
                dispatch(dispatchCallBack(data))
            }).catch((error) => {
                dispatch(showToastError(error.response.data.message))
            });

        } else if (reportType === "employee") {

            getEmployeeReportList(mystate.fromDate, mystate.toDate, mystate.selectedEmployee.map((item) => item.value)).then((response) => {
                const data = response ? response.data.data : []
                dispatch(dispatchCallBack(data))
            }).catch((error) => {
                dispatch(showToastError(error.response.data.message))
            });

        } else if (reportType === "material_usage") {

            getMaterailUsageList(mystate.fromDate, mystate.toDate, mystate.selectedPurpose.map((item) => item.value)).then((response) => {
                const data = response ? response.data.data : []
                dispatch(dispatchCallBack(data))
            }).catch((error) => {
                dispatch(showToastError(error.response.data.message))
            });

        } else {
            getDeadStockItemReportList(mystate.fromDate, mystate.toDate).then((response) => {
                const data = response ? response.data.data : []
                dispatch(dispatchCallBack(data))
            }).catch((error) => {
                dispatch(showToastError(error.response.data.message))
            });
        }

    };
}


export function getMaterialOptionAction() {

    function dispatchCallBack(data) {
        return {
            type: GET_MATERIAL_OPTIONS,
            payload: data,
        };
    }
    return (dispatch, getState) => {
        searchMaterial().then((response) => {
            const data = response ? response.data.data : []
            dispatch(dispatchCallBack(data))

        }).catch((error) => {
            dispatch(showToastError(error.response.data.message))
        });
    }
}


export function handleSelectedMaterialAction(material) {
    return {
        type: HANDLE_SELETED_MATERIAL,
        payload: material,
    };

}

export function getEmployeeOptionAction() {

    function dispatchCallBack(data) {
        return {
            type: GET_EMPLOYEE_OPTIONS,
            payload: data,
        };
    }
    return (dispatch, getState) => {
        searchEmployee("", "", "").then((response) => {
            const data = response ? response.data.data : []
            dispatch(dispatchCallBack(data))

        }).catch((error) => {
            dispatch(showToastError(error.response.data.message))
        });
    }
}


export function handleSelectedEmployeeAction(material) {
    return {
        type: HANDLE_SELETED_EMPLOYEE,
        payload: material,
    };

}

export function getPurposeOptionAction() {

    function dispatchCallBack(data) {
        return {
            type: GET_PURPOSE_OPTIONS,
            payload: data,
        };
    }
    return (dispatch, getState) => {
        searchPurpose("").then((response) => {
            const data = response ? response.data.data : []
            dispatch(dispatchCallBack(data))

        }).catch((error) => {
            dispatch(showToastError(error.response.data.message))
        });
    }
}


export function handleSelectedPurposeAction(material) {
    return {
        type: HANDLE_SELETED_PURPOSE,
        payload: material,
    };

}



export function handleSelectedReportTypeAction(reportType) {
    return {
        type: HANDLE_SELETED_REPORT_TYPE,
        payload: reportType,
    };
}

export function handleSelectedPeriodAction(reportType) {
    return {
        type: HANDLE_SELECTED_PERIOD,
        payload: reportType,
    };
}

export function handleFromToDateAction(fromToDate) {
    return {
        type: HANDLE_FROM_TO_DATE,
        payload: fromToDate,
    };
}

export function handleDataLoadingAction(status) {
    return {
        type: LOADING_DATA_ACTION,
        payload: status,
    };
}

export function showToastError(error) {
    return {
        type: SHOW_TOAST_ERROR,
        payload: error,
    };
}
