import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getBarcodeAction } from "../../../services/ProductService";
import {
  toggleStockinModal,
  toggleStockoutModal,
  setScanBarcode,
} from "../../../store/actions/AuthActions";

const GlobalScan = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [actions, setActions] = useState([]);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setInputValue("");
        setActions([]);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const toggleModal = () => {
    if (modalOpen) {
      setInputValue("");
      setActions([]);
    }
    setModalOpen(!modalOpen);
  };

  const handleInputChange = (event) => {
    const newInputValue = event.target.value;
    setInputValue(newInputValue);
    if (newInputValue.length !== 11) {
      setActions([])
      return;
    }
    getBarcodeAction(newInputValue)
      .then((response) => {
        if (response) {
          setActions(response.data.action);
        }
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };

  const handleResultClick = (action) => {
    if (action === "STOCKIN") {
      dispatch(toggleStockinModal());
      dispatch(setScanBarcode(inputValue));
    } else if (action === "STOCKOUT") {
      dispatch(toggleStockoutModal());
      dispatch(setScanBarcode(inputValue));
    } else if (action === "RACK") {
      navigate(`/rack/${inputValue}`);
    } else {
      navigate(`/stock_history?query=${inputValue}`);
    }
    setInputValue("");
    toggleModal();
    setActions([]);
  };

  return (
    <div
      className={`scan-bar ${actions.length > 0 ? "show-result" : ""}`}
      ref={dropdownRef}
    >
      <div className="input-group scan-area">
        <input
          type="text"
          className="form-control rounded-0"
          placeholder="Scan Barcode"
          onChange={handleInputChange}
          value={inputValue}
        />
        <span className="input-group-text rounded-0">
          {inputValue.length > 0 ? (
            <span
              onClick={() => {
                setInputValue("");
                setActions([]);
              }}
            >
              <i className="fa-regular fa-circle-xmark text-primary c-pointer fa-xl"></i>
            </span>
          ) : (
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                fill="var(--bs-primary)"
                viewBox="0 0 256 256"
              >
                <path d="M232,48V88a8,8,0,0,1-16,0V56H184a8,8,0,0,1,0-16h40A8,8,0,0,1,232,48ZM72,200H40V168a8,8,0,0,0-16,0v40a8,8,0,0,0,8,8H72a8,8,0,0,0,0-16Zm152-40a8,8,0,0,0-8,8v32H184a8,8,0,0,0,0,16h40a8,8,0,0,0,8-8V168A8,8,0,0,0,224,160ZM32,96a8,8,0,0,0,8-8V56H72a8,8,0,0,0,0-16H32a8,8,0,0,0-8,8V88A8,8,0,0,0,32,96ZM80,80a8,8,0,0,0-8,8v80a8,8,0,0,0,16,0V88A8,8,0,0,0,80,80Zm104,88V88a8,8,0,0,0-16,0v80a8,8,0,0,0,16,0ZM144,80a8,8,0,0,0-8,8v80a8,8,0,0,0,16,0V88A8,8,0,0,0,144,80Zm-32,0a8,8,0,0,0-8,8v80a8,8,0,0,0,16,0V88A8,8,0,0,0,112,80Z"></path>
              </svg>
            </span>
          )}
        </span>
      </div>
      <div className="scan-area-result">
        {actions.map((item, index) => (
          <div key={index} className="scan-area-result-item">
            <span onClick={() => handleResultClick(item)}>{item}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GlobalScan;
