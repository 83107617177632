/// Menu
import React, { useContext, useReducer, useState, useEffect } from "react";
import Collapse from 'react-bootstrap/Collapse';


/// Link
import { Link } from "react-router-dom";

import { MenuList } from './Menu';
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import { SVGICON } from "../../constant/theme";
import { connect } from "react-redux";
import { useDispatch } from 'react-redux';
import { toast } from "react-toastify";
import LogoutPage from './Logout';
import { updateProfileAction } from "../../../store/actions/AuthActions";
import { updateProfile } from "../../../services/ProductService";
import { Form, Offcanvas } from "react-bootstrap";

const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

const initialState = {
  active: "/dashboard",
  activeSubmenu: "",
}

const SideBar = ({ user, sideMenu }) => {
  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
  } = useContext(ThemeContext);
  const dispatch = useDispatch()
  const userRole = user?.role || "User"

  const [state, setState] = useReducer(reducer, initialState);
  //For scroll
  const [hideOnScroll, setHideOnScroll] = useState(true)
  const [profileModalOpen, setProfileModalOpen] = useState(false);
  const [empForm, setEmpForm] = useState({ name: '', password: '' })
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y
      if (isShow !== hideOnScroll) {
        setHideOnScroll(isShow)
      }
    },
    [hideOnScroll]
  )

  useEffect(() => {
    const path = window.location.pathname
    setState({ active: path });
  }, [])


  const handleMenuActive = status => {
    setState({ active: status });
  }

  const handleSubmenuActive = (status) => {
    setState({ activeSubmenu: status })
  }
  // Menu dropdown list End

  const toggleProfileModal = () => {
    setEmpForm({ name: user?.name, password: '' })
    setProfileModalOpen(!profileModalOpen)
  }

  const handleChangeAdd = (e) => {
    const { name, value } = e.target
    setEmpForm({ ...empForm, [name]: value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    updateProfile(empForm).then((response) => {
      dispatch(updateProfileAction(response.data.data))
      toast.success(response.data.message)
    }).catch((error) => {
      toast.error(error.response.data.message)
    });
  }

  const getInitials = (fullName) => {
    const nameArray = fullName.split(' ');
    // Get the first letter of the first name
    const firstNameInitial = nameArray[0].charAt(0);
    // Get the first letter of the last name (if available)
    const lastNameInitial = nameArray.length > 1 ? nameArray[nameArray.length - 1].charAt(0) : '';
    // Concatenate the initials
    const initials = firstNameInitial + lastNameInitial;
    return initials.toUpperCase(); // Convert to uppercase if needed
  }

  const FilterMenuList = userRole === "Admin" ? MenuList : MenuList.filter(item => item.can_user_access)

  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  return (
    <div
      className={`deznav  border-right ${iconHover} ${sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
        ? hideOnScroll > 120
          ? "fixed"
          : ""
        : ""
        }`}
    >
      <div className="deznav-scroll">
        <ul className="metismenu" id="menu">
          {FilterMenuList.map((data, index) => {
            let menuClass = data.classsChange;
            if (menuClass === "menu-title") {
              return (
                <li className={menuClass} key={index} >{data.title}</li>
              )
            } else {
              return (
                <li className={` ${state.active === data.to ? 'mm-active' : ''}`}
                  key={index}
                >

                  {data.content && data.content.length > 0 ?
                    <>
                      <Link to={"#"}
                        className="has-arrow"
                        onClick={() => { handleMenuActive(data.title) }}
                      >
                        <div className="menu-icon">
                          {data.iconStyle}
                        </div>
                        {" "}<span className="nav-text">{data.title}
                          {
                            data.update && data.update.length > 0 ?
                              <span className="badge badge-xs badge-danger ms-2">{data.update}</span>
                              :
                              ''
                          }
                        </span>
                      </Link>
                      <Collapse in={!!(state.active === data.title)}>
                        <ul className={`${menuClass === "mm-collapse" ? "mm-show" : ""}`}>
                          {data.content && data.content.map((data, index) => {
                            return (
                              <li key={index}
                                className={`${state.activeSubmenu === data.title ? "mm-active" : ""}`}
                              >
                                {data.content && data.content.length > 0 ?
                                  <>
                                    <Link to={data.to} className={data.hasMenu ? 'has-arrow' : ''}
                                      onClick={() => { handleSubmenuActive(data.title) }}
                                    >
                                      {data.title}
                                    </Link>
                                    <Collapse in={!!(state.activeSubmenu === data.title)}>
                                      <ul className={`${menuClass === "mm-collapse" ? "mm-show" : ""}`}>
                                        {data.content && data.content.map((data, ind) => {
                                          return (
                                            <li key={ind}>
                                              <Link className={`${path === data.to ? "mm-active" : ""}`} to={data.to}>{data.title}</Link>
                                            </li>
                                          )
                                        })}
                                      </ul>
                                    </Collapse>
                                  </>
                                  :
                                  <Link to={data.to}>
                                    {data.title}
                                  </Link>
                                }

                              </li>

                            )
                          })}
                        </ul>
                      </Collapse>
                    </>
                    :
                    <Link to={data.to} onClick={() => { handleMenuActive(data.to) }}>
                      <div className="menu-icon">
                        {data.iconStyle}
                      </div>
                      <span className="nav-text">{data.title}</span>
                      {
                        data.update && data.update.length > 0 ?
                          <span className="badge badge-xs badge-danger ms-2">{data.update}</span>
                          :
                          ''
                      }
                    </Link>
                  }

                </li>
              )
            }
          })}
        </ul>
        {/* end user profile end */}
      </div>
      <div className="user-profile">
        <div className="card user-profile-card">
          <div className="user-profile-item">
            <div className="user-profile-icon">
              <span className="avatar-text">{getInitials(user?.name || '')}</span>
            </div>
            <div className="user-profile-text user-name">
              <h6>{user?.name}</h6>
              <span>PN {user?.personnel_number}</span>
            </div>
          </div>
          <Link onClick={toggleProfileModal} className="user-profile-item">
            <span className="user-profile-icon">{SVGICON.User}</span>
            <span className="user-profile-text">Profile</span>
          </Link>
          {userRole === "Admin" &&
            <Link to={"/settings"} className="user-profile-item">
              <span className="user-profile-icon">{SVGICON.Headersetting}</span>
              <span className="user-profile-text">Settings</span>
            </Link>
          }
          <LogoutPage />
        </div>
      </div>
      <Offcanvas show={profileModalOpen}
        onHide={() => toggleProfileModal()}
        className="offcanvas-end" placement='end'>
        <div className="offcanvas-header">
          <h5 className="modal-title" id="#gridSystemModal">Profile</h5>
          <button type="button" className="btn-close"
            onClick={() => toggleProfileModal()}>
          </button>
        </div>
        <div className="offcanvas-body">
          <div className="container">

            <div className="card card-body mb-3">
              <h5>Profile Details</h5>
              <ul>
                <li className="my-2"><strong>Personnel Number</strong>: {user?.personnel_number}</li>
                <li className="my-2"><strong>Name</strong>: {user?.name}</li>
                <li className="my-2"><strong>Email</strong>: {user?.email}</li>
                <li className="my-2"><strong>Role</strong>: {user?.role}</li>
                <li className="my-2"><strong>Status</strong>: {user?.status}</li>
                {user?.role !== "Admin" ? (
                  <>
                    <li className="my-2"><strong>Phone</strong>: {user?.mobile_number}</li>
                    <li className="my-2"><strong>Designation</strong>: {user?.designation?.name}</li>
                  </>
                ) : null}
              </ul>
            </div>

            <Form onSubmit={(e) => handleSubmit(e)} className="card card-body">
              <div className="row">
                <h5>Update Profile</h5>
                <div className="col-xl-12">
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">Name <span className="text-danger">*</span></label>
                    <input type="text" autoFocus className="form-control" id="name" placeholder="Enter Name"
                      value={empForm.name} name='name' onChange={handleChangeAdd} required />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">New Password <span>(Optional)</span></label>
                    <input type="text" className="form-control" id="password" placeholder="Enter New Password"
                      value={empForm.password} name='password' onChange={handleChangeAdd} />
                  </div>
                </div>
              </div>
              <div>
                <button type="submit" className="btn btn-sm btn-primary me-1">Submit</button>
                <button type="button" onClick={() => toggleProfileModal()} className="btn btn-sm btn-danger light ms-1">Close</button>
              </div>
            </Form>
          </div>
        </div>
      </Offcanvas>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    sideMenu: state.sideMenu
  };
};

export default connect(mapStateToProps)(SideBar);

