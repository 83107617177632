import React, { useState, useEffect, useRef } from 'react';
import { getStockHistoryList } from '../../../services/ProductService';
import Pagination from '../../constant/Pagination';
import { CancelRequest } from '../../../services/AxiosInstance';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import TableLoaderEffect from '../../constant/TableLoader';
import { connect } from 'react-redux';

const statusBtnList = ["All", "In", "Out"]

const StockHistory = ({stockUpdateKey}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [statusFilter, setStatusFilter] = useState(statusBtnList[0]);
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const recordsPage = 25;
    const location = useLocation();
    const navigate = useNavigate()

    const isApiCalled = useRef(false); // Prevents duplicate API calls

    useEffect(() => {
        if (isApiCalled.current) return; // Skip second execution

        setIsLoading(true);
        const queryParams = new URLSearchParams(location.search);
        const query = queryParams.get("query") || '';

        getStockHistoryList(currentPage, recordsPage, query, statusFilter)
            .then((resp) => {
                if (resp) {
                    setData(resp.data.data);
                    setTotal(resp.data.total);
                    setSearchQuery(query);

                    // Ensure we only remove `query` once to prevent re-triggering
                    if (queryParams.has("query")) {
                        isApiCalled.current = true; // Prevent second execution
                        navigate(location.pathname, { replace: true });
                    }
                }
            })
            .catch((error) => {
                console.log(error.response?.data?.message);
            })
            .finally(() => {
                setIsLoading(false);
            });

    }, [location.search, currentPage, recordsPage, statusFilter, stockUpdateKey]);

    const handlePagination = (page) => {
        setIsLoading(true)
        isApiCalled.current = false;
        const new_page = page.selected + 1
        getStockHistoryList(new_page, recordsPage, searchQuery, statusFilter).then((resp) => {
            setData(resp.data.data)
            setTotal(resp.data.total)
            setCurrentPage(new_page)
            setIsLoading(false)
        }).catch((error) => {
            alert(error.response.data.message)
            setIsLoading(false)
        })
    }

    const handleSearchChange = (e) => {
        const { value } = e.target
        if (value.length > 0) {
            CancelRequest()
        }
        getStockHistoryList(currentPage, recordsPage, value, statusFilter).then((resp) => {
            if (resp) {
                setData(resp.data.data)
                setTotal(resp.data.total)
            }
        }).catch((error) => {
            console.log(error.response.data.message)
        })
        setSearchQuery(value)
    }

    const handleHistoryStatus = (status) => {
        isApiCalled.current = false;
        setStatusFilter(status)
    }


    return (
        <>
            {/* <MainPagetitle mainTitle="Employee" pageTitle={'Employee'} parentTitle={'Home'} />   */}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                                    <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                                        <h4 className="heading mb-0">Stock History</h4>
                                        <div className='d-flex align-items'>

                                            <div className="btn-group btn-group-sm" role="group" aria-label="Small button group">
                                                {statusBtnList.map(item => (
                                                    <button key={item} type="button"
                                                        className={`btn ${statusFilter === item ? "btn-primary" : "btn-outline-primary"}`}
                                                        onClick={() => handleHistoryStatus(item)}>{item}</button>
                                                ))}
                                            </div>

                                            <div className='form-group mx-5'>
                                                <input type="search" name="serach" className="form-control "
                                                    placeholder="Search" autoFocus
                                                    value={searchQuery} onChange={handleSearchChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div id="employee-tbl_wrapper" className="dataTables_wrapper no-footer">
                                        <table id="empoloyees-tblwrapper" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Product</th>
                                                    <th>Rack</th>
                                                    <th>Quantity</th>
                                                    <th>Status</th>
                                                    <th>Date</th>
                                                    <th>Employee</th>
                                                    <th>Barcode</th>
                                                    <th>User</th>
                                                    <th>Source</th>


                                                </tr>
                                            </thead>
                                            <tbody>
                                                {isLoading ? <TableLoaderEffect colLength={9} /> : data.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <div className="d-flex flex-column">
                                                                <Link to={`/material/${item.stock.product.id}`} className='text-primary fw-bold'>{item.stock.product.name}</Link>
                                                                <small>{item.stock.product.ucs_code}</small>
                                                            </div>
                                                        </td>
                                                        <td><Link to={`/rack/${item.stock.rack.id}`}>{item.stock.rack ? item.stock.rack.rack_no : null}</Link></td>
                                                        <td><span>{item.quantity}</span></td>
                                                        <td>
                                                            {item.is_stock_out ? (
                                                                <span className={`badge light border-0 badge-danger`}>OUT</span>
                                                            ) : (
                                                                <span className={`badge light border-0 badge-success`}>IN</span>
                                                            )}
                                                        </td>
                                                        <td><span>{item.created_at}</span></td>
                                                        <td>
                                                            <div className="d-flex flex-column">
                                                                <span>{item?.employee?.name || ""}</span>
                                                                <small>{item?.purpose || ""}</small>
                                                            </div>
                                                        </td>
                                                        <td><span>{item.stock.barcode_no}</span></td>
                                                        <td>
                                                            <div className="d-flex flex-column">
                                                                <span>{item.user?.email || "User Deleted"}</span>
                                                                <small>{item.stock.product.ucs_code}</small>
                                                            </div>
                                                        </td>

                                                        <td><span>{item.stock.source}</span></td>


                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                        {isLoading ? <div style={{ height: '4rem' }}></div> : <Pagination
                                            currentPage={currentPage}
                                            recordsPage={recordsPage}
                                            dataLength={total}
                                            handlePagination={handlePagination}
                                        />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
const mapStateToProps = (state) => {
	return {
		stockUpdateKey: state.auth.stockUpdateKey,
	};
};
export default connect(mapStateToProps)(StockHistory);